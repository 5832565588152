import { useQuery } from "react-query";
import { SafeProductionCalendarApi } from "../..";

export const useGetCalendarData = (
  date: string,
  site: string,
  location: string,
  islast7days: boolean
) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["safe-production-calendar", "calendar", "list", date, site, location],
    () => SafeProductionCalendarApi.Calendar.getSpcCalendarData(date, site, location, islast7days),
    { refetchInterval: 600000, enabled: !!site }
  );

  return {
    calendarData: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};
