import React from "react";
import { ThemeProvider, AppContainer } from "@packages/theme-mui-v5";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ApiProvider, WithPermissions } from "@packages/service-api";
import Router from "./Router";
import config from "./config";
import { initializeSentry } from "@packages/sentry";

initializeSentry(config.name);

const SafeProductionCalendar: React.FC = () => {
  return (
    <WithPermissions
      applicationId={config.applicationId}
      permissions={Object.values(config.permissions)}
      redirect={true}
    >
      <ApiProvider>
        <AppContainer appConfig={config} defaultCollapsed>
          <ThemeProvider provider={MUIThemeProvider}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </AppContainer>
      </ApiProvider>
    </WithPermissions>
  );
};

export default SafeProductionCalendar;
