import { Application } from "@nutrien-operations/config";

export const basePath = "/safe-production-calendar";

export const permissions = {
  configureApplication: "51de3ccc-507f-49be-aeae-25db5236d458",
  viewDashboard: "ff06fce6-2bd5-4011-81ed-472e9abdde5c"
};

const applicationConfig: Application = {
  applicationId: "8ff08e75-489f-4346-a5f1-13942cbc7d8f",
  name: "@nutrien-operations/safe-production-calendar",
  displayName: "Safe Production Calendar",
  icon: "calendar-days",
  path: {
    value: basePath
  },
  permissions,
  order: 1,
  menu: {
    label: "Safe Production Calendar",
    path: "/",
    icon: "calendar-days",
    children: [
      {
        label: "Calendar",
        path: "/",
        icon: "calendar-days",
        permission: permissions.viewDashboard
      },
      {
        label: "Admin",
        path: "/admin",
        icon: "users-manage",
        permission: permissions.configureApplication
      }
    ],
    permission: permissions.viewDashboard
  }
};

export default applicationConfig;
